import { Box, Grid, Heading, Stack, Textarea, Text, GridItem } from "@chakra-ui/react";
import { useRef } from "react";

import { Table, TableBody } from "../../../../components/table";
import { DataDisplayRow } from "../../../../components/data-display-row";
import { ExternalLink } from "../../../../components/external-link";
import { PanelBottom, PanelBox, PanelGroup, PanelTop } from "../../../../components/panels";

import { ContentStrategyItem } from "./content-strategy-item";
import { useContentStrategyV2 } from "./content-strategy-v2-hook";
import { Summary } from "./summary";
import { RevisionPanel } from "../../../../common/revisions-panel";
import { FileDropUpload } from "../../../../components/file-drop-upload";
import { PrimaryButton, SecondaryButton } from "../../../../components/buttons";

interface Props extends ReturnType<typeof useContentStrategyV2> {
  taskId: number;
  onAbandon: () => void;
  onComplete: () => void;
  onFileSelected: (file: File) => void;
}

export function ContentStrategyWorkspace(props: Props) {
  const {
    customer,
    customerQuestionnaire,
    orderContentStrategies,
    handleComplete,
    customerProposal,
    refetch,
    reviewTasks,
    onFileSelected,
    onDownloadKeywords,
    onDownloadContentStrategy,
    onGenerateTopics,
    onTopicsChange,
    onFillTopics,
    topics,
    keywordGenerationLoading,
    isLoading,
    prompt,
    onPromptChange,
  } = props;

  const workspaceRef = useRef<({ save: () => void } | null)[]>([]);

  async function onSave() {
    workspaceRef.current.forEach((ref) => {
      if (ref) {
        ref.save();
      }
    });
    await refetch();
  }

  return (
    <Grid gridTemplateColumns="1fr 1.5fr" gap={4}>
      <Stack spacing={4}>
        <RevisionPanel reviewTasks={reviewTasks} />
        <PanelGroup>
          <PanelTop showBottomBorder>
            <Heading size="md">Client Details</Heading>
          </PanelTop>
          <PanelBottom containsTable>
            <Table isEmpty={false} isLoading={false}>
              <TableBody>
                <DataDisplayRow label="Client Name" value={customer?.name ?? "-"} />
                <DataDisplayRow label="About Company" value={customerQuestionnaire?.businessDescription ?? "-"} />
                <DataDisplayRow
                  label="Website URL"
                  value={
                    customer?.website ? (
                      <ExternalLink href={customer.website} target="_blank">
                        {customer.website}
                      </ExternalLink>
                    ) : (
                      "-"
                    )
                  }
                />
                <DataDisplayRow label="Category" value={customer?.category?.name ?? "-"} />
                <DataDisplayRow label="Business Type" value={customerQuestionnaire?.customerMarketSize ?? "-"} />
                <DataDisplayRow label="Target Audience" value={customerQuestionnaire?.targetAudience ?? "-"} />
                <DataDisplayRow
                  label="Competitors/Not Permitted"
                  value={customerQuestionnaire?.competitorWebsites ?? "-"}
                />
                <DataDisplayRow label="Client Instructions" value={customer?.instructions ?? "-"} />
              </TableBody>
            </Table>
          </PanelBottom>
        </PanelGroup>
      </Stack>
      <Stack spacing={4} width={{ md: "900px", sm: "600px" }}>
        <Stack isInline spacing={2}>
          <PrimaryButton onClick={onDownloadKeywords}>Download Keywords</PrimaryButton>
          <PrimaryButton onClick={onDownloadContentStrategy}>Download Content Strategy</PrimaryButton>
        </Stack>
        <Box>
          <FileDropUpload
            acceptedFileExtensions={{
              "application/csv": [".csv"],
            }}
            onFileUpload={onFileSelected}
          />
        </Box>
        <Grid gridAutoFlow="row" overflow="auto">
          <GridItem>
            <PanelBox>
              <Grid templateColumns="repeat(auto-fit, minmax(200px, 1.5fr))" gap={2}>
                <GridItem colSpan={1}>
                  <Text>Product</Text>
                </GridItem>
                <GridItem colSpan={1}>
                  <Text>Start Date</Text>
                </GridItem>
                <GridItem colSpan={1}>
                  <Text>Topic</Text>
                </GridItem>
                <GridItem colSpan={1}>
                  <Text>Primary Keyword</Text>
                </GridItem>
              </Grid>
            </PanelBox>
          </GridItem>
          <GridItem>
            {orderContentStrategies.map((orderContentStrategy, i) => (
              <ContentStrategyItem
                key={orderContentStrategy.id}
                orderContentStrategy={orderContentStrategy}
                workspaceRefetch={refetch}
                ref={(el: { save: () => void } | null) => (workspaceRef.current[i] = el)}
              />
            ))}
          </GridItem>
          <GridItem>
            <PanelBox>
              <PrimaryButton onClick={onSave} isDisabled={isLoading}>
                Save
              </PrimaryButton>
            </PanelBox>
          </GridItem>
        </Grid>
        <PanelBox>
          <Stack spacing={4}>
            <Stack direction="row" justifyContent="flex-start">
              <PrimaryButton onClick={onGenerateTopics} isDisabled={keywordGenerationLoading}>
                Generate Topics
              </PrimaryButton>
              <SecondaryButton onClick={onFillTopics}>Fill topics</SecondaryButton>
            </Stack>
            <Box>
              <Heading size="sm">Prompt</Heading>
              <Textarea value={prompt} onChange={onPromptChange} rows={5} />
            </Box>
            <Box>
              <Heading size="sm">Topics</Heading>
              <Textarea value={topics} onChange={onTopicsChange} rows={5} />
            </Box>
          </Stack>
        </PanelBox>
        <Summary
          customerProposalId={customerProposal?.id ?? 0}
          summary={customerProposal?.summary}
          onAbandon={props.onAbandon}
          handleComplete={handleComplete}
          isDisabled={orderContentStrategies.length === 0}
        />
      </Stack>
    </Grid>
  );
}
