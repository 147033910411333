import React, { useImperativeHandle } from "react";
import { Badge, Box, Flex, FormControl, Grid, GridItem, Input, Select, Tooltip } from "@chakra-ui/react";
import { DateTime } from "luxon";
import { ContentStrategyV2_OrderContentStrategyFragment } from "../../../../generated/graphql";
import { DATE_FORMAT } from "../../../../constants/date-formats";
import { useContentStrategyForm } from "./content-strategy-form-hook";
import { CustomerKeywordSelector, KeywordType } from "../../../../common/customer-keyword-selector";
import { BLOG_POST_PRODUCTS, GUEST_ARTICLE_PRODUCTS, WEB_COPY_PRODUCTS } from "../../../../constants/products";
import { PAGE_TYPES } from "../../../../constants/page-types";
import { AlertInfo } from "../../../../components/alerts";

interface Props {
  isComplete: boolean;
  orderContentStrategy: ContentStrategyV2_OrderContentStrategyFragment;
}

function ContentStrategyFormComp(props: Props, ref: React.Ref<{ save: () => void }>) {
  const { orderContentStrategy, isComplete } = props;

  const { formik } = useContentStrategyForm(props);

  useImperativeHandle(ref, () => ({
    save: () => {
      formik.handleSubmit();
    },
  }));

  if (!orderContentStrategy.order) {
    console.error(`Unable to find order for <orderContentStrategy|${orderContentStrategy.id}>`);
    return null;
  }

  const isGuestArticle = GUEST_ARTICLE_PRODUCTS.includes(orderContentStrategy.order.partnerProduct.product.id);
  const isBlogPost = BLOG_POST_PRODUCTS.includes(orderContentStrategy.order.partnerProduct.product.id);
  const isWebCopy = WEB_COPY_PRODUCTS.includes(orderContentStrategy.order.partnerProduct.product.id);
  const contentPreference = orderContentStrategy.order.customer.customerQuestionnaire?.contentPreference ?? "";

  return (
    <Grid templateColumns="repeat(auto-fit, minmax(200px, 1.5fr))" gap={2}>
      <GridItem colSpan={1}>
        <Input variant="unstyled" value={orderContentStrategy.order.partnerProduct.product.name} isDisabled />
      </GridItem>
      <GridItem colSpan={1}>
        <Input
          variant="unstyled"
          value={DateTime.fromJSDate(orderContentStrategy.order.startDate).toFormat(DATE_FORMAT)}
          isDisabled
        />
        {orderContentStrategy.order.instructions && <AlertInfo>{orderContentStrategy.order.instructions}</AlertInfo>}
      </GridItem>
      <GridItem colSpan={3}>
        <Tooltip label={formik.values.topic ?? ""} aria-label={formik.values.topic ?? ""}>
          <Input variant="outline" id="topic" value={formik.values.topic ?? ""} onChange={formik.handleChange} />
        </Tooltip>
      </GridItem>
      <GridItem colSpan={1}>
        {(isBlogPost || isGuestArticle) && (
          <FormControl isRequired>
            <CustomerKeywordSelector
              keywordTypes={KeywordType.PRIMARY}
              customerId={orderContentStrategy.order.customerId}
              selectedCustomerKeywordId={formik.values.customerKeywordId}
              onSelectedCustomerKeywordChange={(customerKeywordId) => {
                formik.setFieldValue("customerKeywordId", customerKeywordId);
              }}
            />
          </FormControl>
        )}
      </GridItem>
      {isWebCopy && ["Based on Current Content", "The choice is yours"].includes(contentPreference) && (
        <>
          <GridItem colSpan={1}>
            <Input id="webPage" onChange={formik.handleChange} value={formik.values.webPage ?? ""} />
          </GridItem>
          <GridItem colSpan={1}>
            <Select id="pageType" value={formik.values.pageType ?? ""} onChange={formik.handleChange}>
              <option value="" key="default">
                Not Selected
              </option>
              {PAGE_TYPES.map((option) => (
                <option value={option} key={option}>
                  {option}
                </option>
              ))}
            </Select>
          </GridItem>
        </>
      )}
      <GridItem colSpan={1}>
        <Flex justifyContent="space-between" alignItems="flex-end" flexDirection="column">
          {isComplete && (
            <Box>
              <Badge colorScheme="green">Complete</Badge>
            </Box>
          )}
          {!isComplete && (
            <Box>
              <Badge colorScheme="red">Incomplete</Badge>
            </Box>
          )}
        </Flex>
      </GridItem>
    </Grid>
  );
}

export const ContentStrategyForm = React.forwardRef(ContentStrategyFormComp);
