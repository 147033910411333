import Papa from "papaparse";
import { useToast } from "@chakra-ui/react";
import {
  useContentStrategyV2_UpdateOrderContentStrategyMutation,
  useContentStrategyV2TaskWorkspaceQuery,
  useGenerateTopicsContentStrategyLazyQuery,
} from "../../../../generated/graphql";
import { TaskWorkspaceCommonProps } from "../../task-workspace-common-props";
import { useEffect, useMemo, useState } from "react";

export function useContentStrategyV2(options: TaskWorkspaceCommonProps) {
  const { task: propsTask, onComplete } = options;

  const queryResult = useContentStrategyV2TaskWorkspaceQuery({
    fetchPolicy: "network-only",
    variables: {
      taskId: propsTask.id,
      ignoreUserTaskPermission: true,
    },
  });
  const [generateTopicsContentStrategy, { loading: keywordGenerationLoading }] =
    useGenerateTopicsContentStrategyLazyQuery({
      fetchPolicy: "network-only",
    });
  const toast = useToast();
  const [updateOrderContentStrategy] = useContentStrategyV2_UpdateOrderContentStrategyMutation();
  const [topics, setTopics] = useState<string>("");
  const [prompt, setPrompt] = useState<string>("");

  const task = queryResult.data?.task ?? null;
  const customer = task?.customer ?? null;
  const customerQuestionnaire = customer?.customerQuestionnaire ?? null;
  const customerProposal = task?.customerProposal ?? null;
  const orderContentStrategies = useMemo(() => customerProposal?.orderContentStrategies ?? [], [customerProposal]);
  const reviewTasks = (task?.reviewTasks.nodes ?? []).filter((reviewTask) => !!reviewTask.reviewComment);

  const canSubmit = orderContentStrategies.every((x) => x.isComplete);

  useEffect(() => {
    const keywords = orderContentStrategies.map((x) => x.customerKeyword).filter((x) => !!x);

    let keywordPropmpt = `First, provide a topic for the keyword "${keywords[0]?.name ?? ""}".\n`;

    let webpagePrompt = `Use webpage for info for this keyword: ${keywords[0]?.name ?? ""} ${
      keywords[0]?.landingPageUrl ?? customer?.website
    }\n`;

    for (let i = 1; i < keywords.length; i++) {
      keywordPropmpt += `Then, provide a topic for the keyword "${keywords[i]?.name ?? ""}".\n`;
      webpagePrompt += `Use webpage for info for this keyword: ${keywords[i]?.name ?? ""} ${
        keywords[i]?.landingPageUrl ?? customer?.website
      }\n`;
    }

    const prompt = `Generate 1 blog topic ideas in the following alternating order:
    ${keywordPropmpt}
    
    Use other blog posts or articles for ideas.
    ${webpagePrompt}
    
    Continue this alternating order until you have provided ${keywords.length} topics.
    Ensure the topics are relevant, creative, and varied.
    Provide one topic per keyword at a time, alternating between keywords.
    Do not cluster topics for each keyword together; instead, mix topics from different keywords, maintaining the current order.
    The topics should be short and broad, Include related keywords as necessary, but avoid medical advice.
    Visit for additional context for topics:
    Provide all topics in a single list without numbers with exactly one topic per line.
    Do not put any spaces between lines or groups.
    Do not put any numbers, dashes, or other separators between topics.
    Do not put Topic:(change to keyword) at the beginning of each topic, Give the topic only.
    `;

    setPrompt(prompt);
  }, [orderContentStrategies, customer]);

  function onFileSelected(file: any) {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: async (results: any) => {
        let successCount = 0;
        let errorCount = 0;
        for (const result of results.data) {
          const cloneOrderContentStrategy = JSON.parse(JSON.stringify(orderContentStrategies));
          const orderContentStrategy = cloneOrderContentStrategy.find(
            (x: any) => x.order?.id === parseInt(result["orderId"], 10)
          );
          if (orderContentStrategy) {
            orderContentStrategy.topic = result["topic"] ? result["topic"] : orderContentStrategy.topic;
            orderContentStrategy.customerKeywordId = result["customerKeywordId"]
              ? parseInt(result["customerKeywordId"], 10)
              : orderContentStrategy.customerKeywordId;
            orderContentStrategy.orderHaloKeywords = result["orderHaloKeywords"]
              ? result["orderHaloKeywords"].split(" ").map((x: string) => {
                  const customerKeyword = {
                    id: parseInt(x, 10),
                  };
                  return { customerKeyword };
                })
              : orderContentStrategy.orderHaloKeywords;
            orderContentStrategy.purposeOfContent = result["purposeOfContent"]
              ? result["purposeOfContent"]
              : orderContentStrategy.purposeOfContent;
            orderContentStrategy.linkTo = result["linkTo"] ? result["linkTo"] : orderContentStrategy.linkTo;
            orderContentStrategy.voice = result["voice"] ? result["voice"] : orderContentStrategy.voice;
            orderContentStrategy.sampleContent = result["sampleContent"]
              ? result["sampleContent"]
              : orderContentStrategy.sampleContent;
            orderContentStrategy.focusPoints = result["focusPoints"]
              ? result["focusPoints"]
              : orderContentStrategy.focusPoints;
            orderContentStrategy.webPage = result["webPage"] ? result["webPage"] : orderContentStrategy.webPage; //For web copy
            orderContentStrategy.pageType = result["pageType"] ?? orderContentStrategy.pageType; //For web copy

            const response = await updateOrderContentStrategy({
              variables: {
                input: {
                  orderContentStrategyId: orderContentStrategy.id,
                  topic: orderContentStrategy.topic,
                  customerKeywordId: orderContentStrategy.customerKeywordId,
                  orderHaloKeywords: orderContentStrategy.orderHaloKeywords.map((x: any) => x.customerKeyword.id),
                  purposeOfContent: orderContentStrategy.purposeOfContent,
                  linkTo: orderContentStrategy.linkTo,
                  voice: orderContentStrategy.voice,
                  sampleContent: orderContentStrategy.sampleContent,
                  focusPoints: orderContentStrategy.focusPoints,
                  webPage: orderContentStrategy.webPage,
                  pageType: orderContentStrategy.pageType,
                },
              },
            });
            if (response.data?.updateOrderContentStrategy.ok) {
              successCount++;
            } else {
              errorCount++;
            }
          }
        }
        toast({
          title: "File uploaded",
          description: `${successCount} content strategies updated successfully. ${errorCount} content strategies failed to update.`,
          status: "success",
        });
        await queryResult.refetch();
      },
    });
  }

  async function onGenerateTopics() {
    if (!prompt) {
      toast({
        title: "Prompt is required",
        status: "error",
      });
      return;
    }
    const response = await generateTopicsContentStrategy({
      variables: {
        prompt: prompt,
      },
    });
    setTopics(response.data?.openAIChat?.result ?? "");
  }

  async function onFillTopics() {
    const length = orderContentStrategies.length;
    const topicsArray = topics?.split("\n").slice(0, length);

    for (let i = 0; i < length; i++) {
      await updateOrderContentStrategy({
        variables: {
          input: {
            orderContentStrategyId: orderContentStrategies[i].id,
            topic: topicsArray?.[i] ?? "",
          },
        },
      });
    }
    await queryResult.refetch();
  }

  function onTopicsChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    setTopics(e.target.value);
  }

  function onPromptChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    setPrompt(e.target.value);
  }

  function onDownloadKeywords() {
    window.open(`${process.env.REACT_APP_API_BASE}/files/customers/${customer?.id ?? 0}/keywords/cs`, "_blank");
  }

  function onDownloadContentStrategy() {
    window.open(
      `${process.env.REACT_APP_API_BASE}/files/customers/${customer?.id ?? 0}/tasks/${task?.id ?? 0}/cs`,
      "_blank"
    );
  }

  function handleComplete() {
    if (!canSubmit) {
      toast({
        title: "Unable to Submit for Client Review",
        description: "All content strategies must be complete in order to submit for client review.",
        status: "error",
      });
      return;
    }
    onComplete();
  }

  return {
    isLoading: queryResult.loading,
    task,
    customer,
    customerQuestionnaire,
    customerProposal,
    orderContentStrategies,
    reviewTasks,
    canSubmit,
    handleComplete,
    onFileSelected,
    refetch: queryResult.refetch,
    onDownloadKeywords,
    onDownloadContentStrategy,
    onGenerateTopics,
    onTopicsChange,
    onFillTopics,
    topics,
    keywordGenerationLoading,
    prompt,
    onPromptChange,
  };
}
